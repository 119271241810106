import React, { useEffect } from "react";
import CareerBanner from "./BannerSection/CareerBanner";
import Benefit from "./Benefit/Benefit";
import HowtoJoin from "./HowToJoin/HowtoJoin";
import Resume from "./Resume/Resume";
import OpenPosition from "./OpenPosition/OpenPosition";
import NavBar from "../HomePage/NavBar/NavBar";
import Footer from "../HomePage/Footer/Footer";

function Career() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <CareerBanner />
      <Benefit />
      {/* <HowtoJoin /> */}
      <OpenPosition />
      {/* <Resume /> */}
    </div>
  );
}

export default Career;
