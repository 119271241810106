// import React, { useEffect, useState } from "react";
// import ServiceComponent from "./ServiceComponent";
// import Aos from "aos";
// import "aos/dist/aos.css";

// const Service = () => {
//   useEffect(() => {
//     Aos.init({
//       easing: "ease-in-sine",
//     });
//   }, []);

//   const [loading, setLoading] = useState(true);

//   setTimeout(() => {
//     setLoading(false);
//   }, 2000);

//   return (

//     <div className="container-fluid d-flex justify-content-center" style={{ backgroundColor: "#eef3ff" }}>
//       {loading ? (
//         <div>
//           <img
//             src={require("./../../../Assets/Images/sq-lite-filled.png")}
//             className="full-width-image"
//             width="80"
//             height="80"
//           />
//         </div>
//       ) : (
//         <div className="sectionContainer sectionMergin">
//           <div className="row">
//             <div
//               className="col-md-12 d-flex justify-content-center"
//               data-aos="fade-up"
//               style={{
//                 color: "black",
//                 fontWeight: "500",
//                 fontFamily: "Montserrat",
//                 fontSize: "32px",
//               }}
//             >
//               <p className="text-center fs-1 mt-5">Services We Offer</p>
//             </div>
//             <div className="col-md-12" data-aos="fade-up">
//               <ServiceComponent />
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Service;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ServiceComponent from "./ServiceComponent";
import Aos from "aos";
import "aos/dist/aos.css";

const Service = () => {
  useEffect(() => {
    Aos.init({
      easing: "ease-in-sine",
    });
  }, []);

  const [loading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
  }, 2000);

  return (
    <div
      className="container-fluid d-flex justify-content-center"
      style={{ backgroundColor: "#eef3ff" }}
    >
      <Helmet>
        {/* Google Tag Manager script */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-JJ10KFHNF6"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-JJ10KFHNF6');
        `}
        </script>
      </Helmet>
      {loading ? (
        <div>
          <img
            src={require("./../../../Assets/Images/sq-lite-filled.png")}
            className="full-width-image"
            width="80"
            height="80"
          />
        </div>
      ) : (
        <div className="sectionContainer sectionMergin">
          <div className="row">
            <div
              className="col-md-12 d-flex justify-content-center"
              data-aos="fade-up"
              style={{
                color: "black",
                fontWeight: "500",
                fontFamily: "Montserrat",
                fontSize: "32px",
              }}
            >
              <p className="text-center fs-1 mt-5">Services We Offer</p>
            </div>
            <div className="col-md-12" data-aos="fade-up">
              <ServiceComponent />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Service;
