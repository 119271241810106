import React from "react";
import "./../TrustedBy/TrustedBy.css";
import { Swiper, SwiperSlide, Navigation } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

const TrustedBySection = () => {
  return (
    <div className="container-fluid">
      <div className="row d-flex justify-content-center light-grey-section ps-3 pe-3  pb-2">
        <div className="row">
          <p class="d-flex justify-content-center mt-3 font-design">
            <strong>Trusted by</strong>
          </p>
        </div>
        <div
          className="d-flex flex-wrap justify-content-center content-center"
          style={{ flexDirection: "row" }}
        >
          {/* <> */}
          <Swiper
            slidesPerView={1}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              320: {
                slidesPerView: 2,
                // spaceBetween: 10,
              },
              640: {
                slidesPerView: 2,
                // spaceBetween: 10,
              },
              768: {
                slidesPerView: 4,
                // spaceBetween: 30,
              },
              1024: {
                slidesPerView: 6,
                //   spaceBetween: 10,
              },
            }}
            modules={[Autoplay]}
            className="mySwiper ms-20 me-20"
          >
            {/* <SwiperSlide>
              {" "}
              <div>
                <img
                  src={require("./../../Assets/Images/Go5050.png")}
                  width="80"
                  height="90"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div>
                <img
                  src={require("./../../Assets/Images/Gotaste Logo-13.png")}
                  width="80"
                  height="90"
                />
              </div>
            </SwiperSlide> */}
            <SwiperSlide>
              {" "}
              <div>
                <img
                  src={require("./../../Assets/Images/logo (1).png")}
                  className="full-width-image"
                  width="80"
                  height="80"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img
                  src={require("./../../Assets/Images/SproutLogo_big-removebg.png")}
                  className="full-width-image"
                  width="100"
                  height="130"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div>
                <img
                  src={require("./../../Assets/Images/image001.png")}
                  width="80"
                  height="90"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div>
                <img
                  src={require("./../../Assets/Images/team7.png")}
                  className="full-width-image"
                  width="100"
                  height="120"
                />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              {" "}
              <div>
                <img
                  src={require("./../../Assets/Images/team6.png")}
                  className="full-width-image"
                  width="80"
                  height="80"
                />
              </div>
            </SwiperSlide>

            {/* <SwiperSlide>
              {" "}
              <div>
                <img
                  src={require("./../../Assets/Images/ModinaLogo.png")}
                  alt="Virgin Care logo"
                  className="full-width-image"
                  width="80"
                  height="80"
                />
              </div>
            </SwiperSlide> */}

            <SwiperSlide>
              <div>
                <img
                  src={require("./../../Assets/Images/SMKW_logo3.png")}
                  alt="Kent Invicta Chamber of Commerce logo"
                  className="full-width-image"
                  width="80"
                  height="80"
                />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              {" "}
              <div>
                <img
                  src={require("./../../Assets/Images/rhti-logo.png")}
                  className="full-width-image"
                  width="80"
                  height="80"
                />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              {" "}
              <div>
                <img
                  src={require("./../../Assets/Images/team5.png")}
                  className="full-width-image"
                  width="80"
                  height="110"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div>
                <img
                  src={require("./../../Assets/Images/team4.png")}
                  alt="NHS logo"
                  className="full-width-image"
                  width="80"
                  height="80"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div>
                <img
                  src={require("./../../Assets/Images/team3.png")}
                  alt="Virgin Care logo"
                  className="full-width-image"
                  width="80"
                  height="80"
                />
              </div>
            </SwiperSlide>

            {/* <div  >
          <img
            src="https://www.supersonicplayground.com/wp-content/uploads/2022/01/kent-county-council-logo-padding.svg"
            alt=""
            className="full-width-image"
            width="150"
            height="100"
          />
        </div> */}
            {/* </div> */}
          </Swiper>
        </div>
        {/* </> */}
      </div>
    </div>
  );
};

export default TrustedBySection;

// import './../TrustedBy/TrustedBy.css'
// import React from 'react';

//     const TrustedBySection = () => {
//       return (
//         <div className="outer-container light-grey-section trusted-by-section">
//           <div className="inner-container center-container vertical-half-padding-top">
//             <div className="full-page center-align">
//               <p><strong>Trusted by</strong></p>
//             </div>
//           </div>
//           <div className="inner-container center-container">
//             <div className="one-sixth-page center-align">
//               <img
//                 src="https://www.supersonicplayground.com/wp-content/uploads/2019/01/logo-nhs-tiny.svg"
//                 alt="NHS logo"
//                 className="full-width-image"
//                 width="150"
//                 height="100"
//               />
//             </div>
//             <div className="one-sixth-page center-align">
//               <img
//                 src="https://www.supersonicplayground.com/wp-content/uploads/2019/01/logo-virgincare-tiny.svg"
//                 alt="Virgin Care logo"
//                 className="full-width-image"
//                 width="150"
//                 height="100"
//               />
//             </div>
//             <div className="one-sixth-page center-align">
//               <img
//                 src="https://www.supersonicplayground.com/wp-content/uploads/2019/01/logo-npc-tiny.svg"
//                 alt="NPC logo"
//                 className="full-width-image"
//                 width="150"
//                 height="100"
//               />
//             </div>
//             <div className="one-sixth-page center-align">
//               <img
//                 src="https://www.supersonicplayground.com/wp-content/uploads/2019/01/logo-kent-invicta-chamber-of-commerce-tiny.svg"
//                 alt="Kent Invicta Chamber of Commerce logo"
//                 className="full-width-image"
//                 width="150"
//                 height="100"
//               />
//             </div>
//             <div className="one-sixth-page center-align">
//               <img
//                 src="https://www.supersonicplayground.com/wp-content/uploads/2019/01/logo-hospice-uk-tiny.svg"
//                 alt="Hospice UK logo"
//                 className="full-width-image"
//                 width="150"
//                 height="100"
//               />
//             </div>
//             <div className="one-sixth-page center-align">
//               <img
//                 src="https://www.supersonicplayground.com/wp-content/uploads/2022/01/kent-county-council-logo-padding.svg"
//                 alt=""
//                 className="full-width-image"
//                 width="150"
//                 height="100"
//               />
//             </div>
//           </div>
//         </div>
//       );
//     };

//     export default TrustedBySection;
