import React from "react";
// import bannerImage from "./../../../Assets/Images/CareerBanner.png";
import bannerImage from "./../../../Assets/Images/home_bg.jpg";


function ServiceBanner() {
  return (
    <div>
      <div>
        <div className="container-fluid sectionMergin">
          <div className="row d-flex align-items-center ">
            <div className="col-md-7 col-12">
              <div>
                <div className=" bannerTextAlignment d-flex justify-content-md-end justify-content-center  text-uppercase ">
                  <h1>Software Development</h1>
                </div>

                <div className="text-uppercase">
                  <p className="bannerTextAlignment">
                    Our company specializes in software outsourcing and provides
                    robust, scalable, and efficient solutions to clients around
                    the world. We are committed to providing quality services at
                    competitive prices.
                  </p>
                </div>
                <div className="d-flex justify-content-md-end justify-content-center">
                  <a href="#" className=" button fs-5">
                    Contact
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="bannerImage d-flex justify-content-md-start justify-content-center">
                <img src={bannerImage} alt=".." className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceBanner;
