// import React from "react";
// import locationImg from "./../../../Assets/Images/location-pin.png";
// import telImg from "./../../../Assets/Images/telephone.png";
// import { useNavigate } from "react-router-dom";
// import "./Footer.css";

// function Footer(props) {
//   const navigate = useNavigate();

//   return (
//     <div className="footerContainer footer-font">
//       {/* <div
//         className="container-fluid ps-5 pe-5 pt-3 pb-4 footer-container"
//         style={{ background: "#213343", color: "#b6c7d6" }}
//       >
//         <div className="row">
//           <div className="col-md-3">
//             <p>
//               <img
//                 className="mt-3"
//                 src={require("./../../../Assets/Images/Logo-removebg-preview_nobg.png")}
//                 alt=""
//                 style={{ width: "60%" }}
//               />{" "}
//             </p>
//           </div>
//           <div className="col-md-3 pt-1  mt-4">
//             <h6>
//               <b>Head Office</b>
//             </h6>
//             <p className="">
//               <img
//                 className="me-3"
//                 src={require("../../../Assets/Images/location-icon.png")}
//                 alt=""
//                 style={{ width: "1.6rem", right: "5px" }}
//               />
//               Brayford Squar, London, E1 0SG
//             </p>
//             <p className="footer-font" style={{ color: "#b6c7d6" }}>
//               <img
//                 className="me-2"
//                 src={require("../../../Assets/Images/email.png")}
//                 alt=""
//                 style={{ width: "1.6rem" }}
//               />{" "}

//             </p>
//             <img
//               className="me-2"
//               src={telImg}
//               alt=""
//               style={{ width: "1.6rem" }}
//             />{" "}
//             <a
//               className="footer-font"
//               href="tel:+8801776013574"
//               style={{ color: "#b6c7d6" }}
//             >
//               +442081671737
//             </a>
//           </div>
//           <div className="col-md-3 mt-4">
//             <h6>
//               <b>Corp. Office</b>
//             </h6>
//             <p>
//               <img
//                 className="me-2"
//                 src={require("../../../Assets/Images/location-icon.png")}
//                 alt=""
//                 style={{ width: "1.6rem" }}
//               />
//               Banasree-B ,Road-1, House-21, Dhaka-1219
//             </p>
//             <img src={telImg} alt="" style={{ width: "1.6rem" }} />{" "}
//             <a
//               className="footer-font"
//               href="tel:+8801776013574"
//               style={{ textDecoration: "none", color: "#b6c7d6" }}
//             >
//               +8801776013574
//             </a>
//           </div>
//           <div className="col-md-3 mt-3">
//             <div className="row">
//               <div className="col-12 ">
//                 <h5
//                   className="font-size-for-footer"
//                   onClick={() => {
//                     navigate("/");
//                   }}
//                 >
//                   Home
//                 </h5>
//               </div>
//               <div className="col-12 ">
//                 <h5
//                   className="font-size-for-footer"
//                   onClick={() => {
//                     navigate("/Career");
//                   }}
//                 >
//                   Career
//                 </h5>
//               </div>
//               <div className="col-12">
//                 <h5
//                   className="font-size-for-footer"
//                   onClick={() => {
//                     navigate("/our-services");
//                   }}
//                 >
//                   Our Services
//                 </h5>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="container-fluid" style={{ backgroundColor: "#001b36" }}>
//         <div className="row">
//           <div className="col-12">
//             <h5 className="text-center" style={{ color: " #F8F6F0" }}>
//               © 2023 <b>Quad Theory Ltd</b>
//             </h5>
//           </div>
//         </div>
//       </div> */}
//       <div className="container pt-5 pb-3">
// <div className="row text-center text-md-start">
//   <div className="col-12">
//     <div className="logo-center">
//       <img
//         className="ms-3"
//         src={require("./../../../Assets/Images/Logo-removebg-preview.png")}
//         alt=""
//         style={{ width: "8rem" }}
//       />
//       <h5 style={{ color: "whitesmoke" }}>Quad Theory Ltd</h5>
//     </div>
//   </div>
// </div>

//         <div clTzazassName="row">
//           <div className="col-md-3 col-0 ">

//             <div
//               className="d-flex mt-3"
//               style={{ gap: "10px", color: " #F8F6F0" }} >
//               <div className="desktopView">
//                 <img
//                   src={require("./../../../Assets/Images/office.png")}
//                   alt=""
//                   style={{ width: "1rem" }}
//                 />
//               </div>
//               <div className="d-flex" style={{ flexDirection: "column" }}>
//                 <div className="d-flex" style={{gap:"10px"}}>
//                   <div>
//                   <img
//                   src={require("./../../../Assets/Images/office.png")}
//                   alt=""
//                   style={{ width: "1rem" }}
//                   className="addressView"
//                 />
//                   </div>

//                 <h5> UK Office</h5>
//                 </div>

//                 <p className="m-0"> Brayford Squar, London, E1 0SG</p>
//                 <a
//                   href="tel:+8801776013574"
//                   style={{ textDecoration: "none", color: " #F8F6F0" }}
//                 >
//                   +442081671737
//                 </a>
//                 {/* <a
// href="mailto:info@tzazinfotech.co.uk"
//   style={{ textDecoration: "none", color: "#F8F6F0" }}
// >
//  info@quadtheoryltd.com
//                 </a> */}
//               </div>
//             </div>

//             <hr style={{ color: "#cb813e" }} />

//             <div
//               className="d-flex mt-3"
//               style={{ gap: "10px", color: " #F8F6F0" }}
//             >
//               <div>
//               <div className="desktopView">
//                 <img
//                   src={require("./../../../Assets/Images/office.png")}
//                   alt=""
//                   style={{ width: "1rem" }}
//                 />
//               </div>
//               <img
//                   src={require("./../../../Assets/Images/office.png")}
//                   alt=""
//                   style={{ width: "1rem" }}
//                   className="addressView"
//                 />
//               </div>
//               <div className="d-flex" style={{ flexDirection: "column" }}>

//                 <h5> Bangladesh Office</h5>
//                 <p className="m-0"> Banasree-B ,Road-1, House-21, Dhaka-1219</p>
//                 <a
//                   href="tel: +8801776013574"
//                   style={{ textDecoration: "none", color: " #F8F6F0" }}
//                 >
//                   +8801776013574
//                 </a>
//                 <a
// href="mailto:info@quadtheoryltd.com"
//                   style={{ textDecoration: "none", color: "#F8F6F0" }}
//                 >
//                   info@quadtheoryltd.com
//                 </a>
//               </div>
//             </div>
//           </div>

//           <div
//             className="col-md-3"
//             style={{ color: " #F8F6F0", marginTop: "12px" }}
//           >
//             <h5 className="linksHeader">UseFull Links</h5>
//             <ul>
//               <li>
//                 <a
//                   href="/"
//                   style={{ color: " #F8F6F0", textDecoration: "none" }}
//                 >
//                   Home
//                 </a>{" "}
//               </li>
//               <li>
//                 <a
//                   href="/Career"
//                   style={{ color: " #F8F6F0", textDecoration: "none" }}
//                 >
//                   Career
//                 </a>
//               </li>
//               <li>
//                 <a
//                   href="/our-services"
//                   style={{ color: " #F8F6F0", textDecoration: "none" }}
//                 >
//                   Our Services
//                 </a>
//               </li>
//             </ul>
//           </div>
//           <div
//             className="col-md-4 "
//             style={{ color: " #F8F6F0", marginTop: "12px" }}
//           >
//             <h5 className="linksHeader">Our Services</h5>
//             <ul>
//               <li>
//                 <a
//                   href="/offshore-developer-services-quad-theory-ltd"
//                   style={{ color: " #F8F6F0", textDecoration: "none" }}
//                 >
//                   Offshore Developer Service
//                 </a>{" "}
//               </li>
//               <li>
//                 <a
//                   href="/web-development-software-company"
//                   style={{ color: " #F8F6F0", textDecoration: "none" }}
//                 >
//                   Web Development
//                 </a>
//               </li>
//               <li>
//                 <a
//                   href="/business-analysis-software-company"
//                   style={{ color: " #F8F6F0", textDecoration: "none" }}
//                 >
//                   Business Analysis
//                 </a>
//               </li>
//               <li>
//                 <a
//                   href="/communications-technology-solutions-software-company"
//                   style={{ color: " #F8F6F0", textDecoration: "none" }}
//                 >
//                   Communications Technology
//                 </a>
//               </li>
//               <li>
//                 <a
//                   href="/data-analytics-software-company"
//                   style={{ color: " #F8F6F0", textDecoration: "none" }}
//                 >
//                   Data Analytics
//                 </a>
//               </li>
//               <li>
//                 <a
//                   href="/outsourcing-services-software-companies"
//                   style={{ color: " #F8F6F0", textDecoration: "none" }}
//                 >
//                   Outsourcing services
//                 </a>
//               </li>
//               <li>
//                 <a
//                   href="/business-intelligence-software-company"
//                   style={{ color: " #F8F6F0", textDecoration: "none" }}
//                 >
//                   Business Intelligence
//                 </a>
//               </li>
//               <li>
//                 <a
//                   href="/consulting-software-company"
//                   style={{ color: " #F8F6F0", textDecoration: "none" }}
//                 >
//                   Consulting
//                 </a>
//               </li>{" "}
//               <a
//                 href="/our-services"
//                 style={{ color: " #F8F6F0", textDecoration: "none" }}
//               >
//                 See More
//               </a>
//             </ul>
//           </div>
//           <div
//             className="col-md-2"
//             style={{ color: " #F8F6F0", marginTop: "10px" }}
//           >
//             <h5 className="linksHeader">Follow us</h5>
//             <div className="d-flex addressClassFooter" style={{ gap: "10px" }}>
//               <div>
// <a
//   href="https://www.facebook.com/QuadTheoryLtd"
//   target="_blank"
//   rel="noopener noreferrer"
// >
//   <img
//     src={require("./../../../Assets/Images/facebook.png")}
//     alt=""
//     style={{ width: "1.5rem" }}
//   />
// </a>
//               </div>
//               <div>
//                 <img
//                   src={require("./../../../Assets/Images/twitter.png")}
//                   alt=""
//                   style={{ width: "1.5rem" }}
//                 />
//               </div>
//               <div>
//                 <img
//                   src={require("./../../../Assets/Images/linkedin.png")}
//                   alt=""
//                   style={{ width: "1.5rem" }}
//                 />
//               </div>
//               <div>
//                 <img
//                   src={require("./../../../Assets/Images/instagram.png")}
//                   alt=""
//                   style={{ width: "1.5rem" }}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//         <hr style={{ color: "#5f5d61" }} />
//         <div className="row mt-3">
//           <div
//             className="col-12 d-flex justify-content-center"
//             style={{ color: " #F8F6F0" }}
//           >
//             <p className="m-0">
//               {" "}
//               © 2023 <b>Quad Theory Ltd</b>
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Footer;

import React from "react";
import locationImg from "./../../../Assets/Images/location-pin.png";
import telImg from "./../../../Assets/Images/telephone.png";
import { useNavigate } from "react-router-dom";
import "./Footer.css";

function Footer(props) {
  const navigate = useNavigate();

  return (
    <div className="footerContainer footer-font">
      {/* <div
        className="container-fluid ps-5 pe-5 pt-3 pb-4 footer-container"
        style={{ background: "#213343", color: "#b6c7d6" }}
      >
        <div className="row">
          <div className="col-md-3">
            <p>
              <img
                className="mt-3"
                src={require("./../../../Assets/Images/Logo-removebg-preview_nobg.png")}
                alt=""
                style={{ width: "60%" }}
              />{" "}
            </p>
          </div>
          <div className="col-md-3 pt-1  mt-4">
            <h6>
              <b>Head Office</b>
            </h6>
            <p className="">
              <img
                className="me-3"
                src={require("../../../Assets/Images/location-icon.png")}
                alt=""
                style={{ width: "1.6rem", right: "5px" }}
              />
              Brayford Squar, London, E1 0SG
            </p>
            <p className="footer-font" style={{ color: "#b6c7d6" }}>
              <img
                className="me-2"
                src={require("../../../Assets/Images/email.png")}
                alt=""
                style={{ width: "1.6rem" }}
              />{" "}
              info@tzazinfotech.co.uk
            </p>
            <img
              className="me-2"
              src={telImg}
              alt=""
              style={{ width: "1.6rem" }}
            />{" "}
            <a
              className="footer-font"
              href="tel:+8801776013574"
              style={{ color: "#b6c7d6" }}
            >
              +442081671737
            </a>
          </div>
          <div className="col-md-3 mt-4">
            <h6>
              <b>Corp. Office</b>
            </h6>
            <p>
              <img
                className="me-2"
                src={require("../../../Assets/Images/location-icon.png")}
                alt=""
                style={{ width: "1.6rem" }}
              />
              Banasree-B ,Road-1, House-21, Dhaka-1219
            </p>
            <img src={telImg} alt="" style={{ width: "1.6rem" }} />{" "}
            <a
              className="footer-font"
              href="tel:+8801776013574"
              style={{ textDecoration: "none", color: "#b6c7d6" }}
            >
              +8801776013574
            </a>
          </div>
          <div className="col-md-3 mt-3">
            <div className="row">
              <div className="col-12 ">
                <h5
                  className="font-size-for-footer"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Home
                </h5>
              </div>
              <div className="col-12 ">
                <h5
                  className="font-size-for-footer"
                  onClick={() => {
                    navigate("/Career");
                  }}
                >
                  Career
                </h5>
              </div>
              <div className="col-12">
                <h5
                  className="font-size-for-footer"
                  onClick={() => {
                    navigate("/our-services");
                  }}
                >
                  Our Services
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid" style={{ backgroundColor: "#001b36" }}>
        <div className="row">
          <div className="col-12">
            <h5 className="text-center" style={{ color: " #F8F6F0" }}>
              © 2023 <b>TZAZ INFOTECH</b>
            </h5>
          </div>
        </div>
      </div> */}
      <div className="container pt-5 pb-3">
        <div className="row text-center text-md-start">
          <div className="col-12">
            <div className="logo-center">
              <img
                className="ms-3"
                src={require("./../../../Assets/Images/Logo-removebg-preview.png")}
                alt=""
                style={{ width: "8rem" }}
              />
              <h5 style={{ color: "whitesmoke" }}>Quad Theory Ltd</h5>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-lg-3 ">
            <div
              className="d-flex mt-3 text-md-start text-center"
              style={{ gap: "10px", color: " #F8F6F0" }}
            >
              <div className="desktopView">
                <img
                  src={require("./../../../Assets/Images/office.png")}
                  alt=""
                  style={{ width: "1rem" }}
                />
              </div>
              <div
                className="d-flex footerAddress"
                style={{ flexDirection: "column" }}
              >
                <div className="d-flex" style={{ gap: "10px" }}>
                  <div>
                    <img
                      src={require("./../../../Assets/Images/office.png")}
                      alt=""
                      style={{ width: "1rem" }}
                      className="addressView"
                    />
                  </div>

                  <h5> UK Office</h5>
                </div>
                <p className="m-0">
                  {" "}
                  Brayford Squar, London
                  {/* , E1 0SG */}
                </p>
                <a
                  href="tel:+8801776013574"
                  style={{ textDecoration: "none", color: " #F8F6F0" }}
                >
                  +442081671737
                </a>
                {/* <a
                  href="mailto:info@quadtheoryltd.com"
                  style={{ textDecoration: "none", color: "#F8F6F0" }}
                >
                  info@quadtheoryltd.com
                </a> */}
              </div>
            </div>
            <hr style={{ color: "#cb813e" }} />
            <div
              className="d-flex mt-3 text-md-start text-center"
              style={{ gap: "10px", color: " #F8F6F0" }}
            >
              <div className="desktopView">
                <img
                  src={require("./../../../Assets/Images/office.png")}
                  alt=""
                  style={{ width: "1rem" }}
                />
              </div>
              <div
                className="d-flex footerAddress"
                style={{ flexDirection: "column" }}
              >
                <div className="d-flex" style={{ gap: "10px" }}>
                  <div>
                    <img
                      src={require("./../../../Assets/Images/office.png")}
                      alt=""
                      style={{ width: "1rem" }}
                      className="addressView"
                    />
                  </div>

                  <h5> Bangladesh Office</h5>
                </div>
                <p className="m-0"> Banasree-B ,Road-1, House-21, Dhaka-1219</p>
                {/* <a
                  href="tel: +8801776013574"
                  style={{ textDecoration: "none", color: " #F8F6F0" }}
                >
                  +8801776013574
                </a> */}

                <a
                  href="mailto:info@quadtheoryltd.com"
                  style={{ textDecoration: "none", color: "#F8F6F0" }}
                >
                  info@quadtheoryltd.com
                </a>
              </div>
            </div>
          </div>
          <hr style={{ color: "transparent" }} className="addressHrMobile" />
          <div
            className="col-md-4 col-lg-3 foolterl-ul"
            style={{ color: " #F8F6F0", marginTop: "12px" }}
          >
            <h5 className="linksHeader ms-lg-3">UseFull Links</h5>
            <ul>
              <li>
                <a
                  href="/"
                  style={{ color: " #F8F6F0", textDecoration: "none" }}
                >
                  Home
                </a>{" "}
              </li>
              <li>
                <a
                  href="/Career"
                  style={{ color: " #F8F6F0", textDecoration: "none" }}
                >
                  Career
                </a>
              </li>
              <li>
                <a
                  href="/our-services"
                  style={{ color: " #F8F6F0", textDecoration: "none" }}
                >
                  Our Services
                </a>
              </li>
            </ul>
          </div>
          <hr style={{ color: "transparent" }} className="addressHrMobile" />
          <div
            className="col-md-4 col-lg-3 foolterl-ul"
            style={{ color: " #F8F6F0", marginTop: "12px" }}
          >
            <h5 className="linksHeader ms-lg-3">Our Services</h5>
            <ul>
              <li>
                <a
                  href="/offshore-developer-services-quad-theory-ltd"
                  style={{ color: " #F8F6F0", textDecoration: "none" }}
                >
                  Offshore Developer Service
                </a>{" "}
              </li>
              <li>
                <a
                  href="/web-development-software-company"
                  style={{ color: " #F8F6F0", textDecoration: "none" }}
                >
                  Web Development
                </a>
              </li>
              <li>
                <a
                  href="/business-analysis-software-company"
                  style={{ color: " #F8F6F0", textDecoration: "none" }}
                >
                  Business Analysis
                </a>
              </li>
              <li>
                <a
                  href="/communications-technology-solutions-software-company"
                  style={{ color: " #F8F6F0", textDecoration: "none" }}
                >
                  Communications Technology
                </a>
              </li>
              <li>
                <a
                  href="/data-analytics-software-company"
                  style={{ color: " #F8F6F0", textDecoration: "none" }}
                >
                  Data Analytics
                </a>
              </li>
              <li>
                <a
                  href="/outsourcing-services-software-companies"
                  style={{ color: " #F8F6F0", textDecoration: "none" }}
                >
                  Outsourcing services
                </a>
              </li>
              <li>
                <a
                  href="/business-intelligence-software-company"
                  style={{ color: " #F8F6F0", textDecoration: "none" }}
                >
                  Business Intelligence
                </a>
              </li>
              <li>
                <a
                  href="/consulting-software-company"
                  style={{ color: " #F8F6F0", textDecoration: "none" }}
                >
                  Consulting
                </a>
              </li>{" "}
              <a
                href="/our-services"
                style={{ color: " #F8F6F0", textDecoration: "none" }}
              >
                See More
              </a>
            </ul>
          </div>
          <div
            className="col-md-12 col-lg-2 text-lg-start text-center"
            style={{ color: " #F8F6F0", marginTop: "10px" }}
          >
            <h5 className="linksHeader ms-lg-4">Follow us</h5>
            <div
              className="d-flex addressClassFooter justify-content-center"
              style={{ gap: "10px" }}
            >
              <div>
                <a
                  href="https://www.facebook.com/QuadTheoryLtd"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require("./../../../Assets/Images/facebook.png")}
                    alt=""
                    style={{ width: "1.5rem" }}
                  />
                </a>
              </div>
              {/* <div>
                <img
                  src={require("./../../../Assets/Images/twitter.png")}
                  alt=""
                  style={{ width: "1.5rem" }}
                />
              </div> */}
              <div>
                <a
                  href="https://www.linkedin.com/company/quad-theory-ltd/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require("./../../../Assets/Images/linkedin.png")}
                    alt=""
                    style={{ width: "1.5rem" }}
                  />
                </a>
              </div>
              {/* <div>
                <img
                  src={require("./../../../Assets/Images/instagram.png")}
                  alt=""
                  style={{ width: "1.5rem" }}
                />
              </div> */}
            </div>
          </div>
        </div>
        <hr style={{ color: "#5f5d61" }} />
        <div className="row mt-3">
          <div
            className="col-12 d-flex justify-content-center"
            style={{ color: " #F8F6F0" }}
          >
            <p className="m-0">
              {" "}
              © 2023 <b>Quad Theory Ltd</b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
