import React from "react";
import { useParams } from "react-router";
import ProjectBanner from "./ProjectBanner/ProjectBanner";
import Feature from "./Features/Feature";
import ProjectTechnology from "./ProjectTechnology/ProjectTechnology";
import ProjectSugestation from "./ProjectSugestation/ProjectSugestation";
import Images from "./Images/Images";
import './Project.css'

function Project() {
  let { id } = useParams();
  return (
    <div>
      <ProjectBanner id={id} />
      <Feature id={id} />
      <Images />
      <ProjectTechnology id={id} />
      <ProjectSugestation />
    </div>
  );
}

export default Project;
