import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";
import NavBar from "../../HomePage/NavBar/NavBar";
import BannerSection from "../../HomePage/BannerSection/BannerSection";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./ServiceDetailsView.css";
import Footer from "../../HomePage/Footer/Footer";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper/modules";

import img1 from "./../../../Assets/Images/S1/WhatsApp Image 2023-10-14 at 18.03.28_6856b413.jpg";
import img2 from "./../../../Assets/Images/S1/p2.jpg";
import img3 from "./../../../Assets/Images/S1/s2.png";
import img4 from "./../../../Assets/Images/S1/s3.png";
import Test from "../../../test";

function ServiceDetailsView() {
  const [serviceData, setData] = useState();
  let { slug } = useParams();
  const [loading, setIsloading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(slug);
    getAllData();
  }, []);

  const getAllData = () => {
    setIsloading(true);
    axios
      .get(
        "https://testwebapiqt.azurewebsites.net/api/Service/getAllServicedetailsbyslug?slug=" +
          slug
      )
      .then((response) => {
        setData(response.data);
        setIsloading(false);
      });
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <img src={require("./../../../Assets/Images/Loader.gif")} />
      </div>
    );
  } else {
    return (
      <div>
        {serviceData !== undefined ? (
          <>
            <HelmetProvider>
              <Helmet>
                <meta charSet="utf-8" />
                <title>{serviceData.serviceDetail.title}</title>
                <meta
                  name="description"
                  content={serviceData.serviceDetail.description}
                />
              </Helmet>
            </HelmetProvider>
            <BannerSection fromText={serviceData.serviceDetail.title} />

            <div className="serviceDetailsPage">
              <nav aria-label="breadcrumb ">
                <ol className="breadcrumb justify-content-end mb-3 me-3">
                  <li className="breadcrumb-item">
                    <Link to={"/"} className="breadCrumbText">
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"/our-services"} className="breadCrumbText">
                      Our Services
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {serviceData.serviceDetail.title}
                  </li>
                </ol>
              </nav>
              {slug != "web-development-software-company" ? (
                parse(serviceData.serviceDetail.contents)
              ) : (
                <Test />
              )}

              {serviceData.faqs !== undefined && serviceData.faqs !== null ? (
                <div className="row ">
                  <h2 class="mt-5">Frequently Asked Questions (FAQs)</h2>
                  <div
                    className="accordion d-grid gap-4 sectionContainer"
                    style={{ margin: "Auto" }}
                  >
                    {serviceData.faqs.map((data, i) => {
                      return (
                        <>
                          <div
                            className="accordion-item rounded-4"
                            data-aos="fade-up"
                            data-aos-anchor-placement="bottom-bottom"
                            key={i}
                          >
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button gradientColor collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={"#" + i}
                                // aria-expanded="true"
                                aria-controls={i}
                                style={{ color: "white" }}
                              >
                                {data.question}
                              </button>
                            </h2>
                            <div
                              id={i}
                              className="accordion-collapse collapse "
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                              style={{
                                backgroundColor: "#117488",
                                color: "white",
                                fontSize: "18px",
                              }}
                            >
                              <div className="accordion-body">
                                {data.answer}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          "Loading"
        )}
      </div>
    );
  }
}

export default ServiceDetailsView;
