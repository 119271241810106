import React, { useEffect, useState } from "react";
import axios from "axios";
import ProcessComponent from "../ProcessComponent/ProcessComponent";
import ProcessComponentReverse from "../ProcessComponent/ProcessComponentReverse";

const Process = ({ id }) => {
  const [getData, setData] = useState([]);
  useEffect(() => {
    axios
      .get(
        `https://testwebapiqt.azurewebsites.net/api/ServiceProcess/getServiceProcessById?id=${id}`
      )
      .then((response) => {
        setData(response.data);
      });
  }, []);
  return (
    <div>
      <div>
        <div>
          <div className="d-flex justify-content-center ">
            <div className="sectionContainer  ">
              <div className="row">
                <div className="col-md-12 d-flex justify-content-center p-4  ">
                  <h2 className=" fs-1">Process</h2>
                </div>
              </div>
              <div className="row">
                <div className="d-flex justify-content-center">
                  <div className="col-md-10">
                    <div className="d-flex justify-content-center pb-4">
                      <h6>
                        Become a part of the Quad family through our thorough
                        4-step hiring process.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                {getData.map((data) => {
                  return (
                    <div className="col-md-12" key={data.processName}>
                      {data.processNumber === 1 ? (
                        <ProcessComponent
                          processname={data.processName}
                          description={data.description}
                          processimage={data.processImage}
                        />
                      ) : null}
                      {data.processNumber === 2 ? (
                        <ProcessComponentReverse
                          processname={data.processName}
                          description={data.description}
                          processimage={data.processImage}
                        />
                      ) : null}
                      {data.processNumber === 3 ? (
                        <ProcessComponent
                          processname={data.processName}
                          description={data.description}
                          processimage={data.processImage}
                        />
                      ) : null}
                      {data.processNumber === 4 ? (
                        <ProcessComponentReverse
                          processname={data.processName}
                          description={data.description}
                          processimage={data.processImage}
                        />
                      ) : null}

                      {/* <ProcessComponent
                        processname={data.processName}
                        description={data.description}
                        processimage={data.processImage}
                      />
                      <ProcessComponentReverse
                        processname={data.processName}
                        description={data.description}
                        processimage={data.processImage}
                      /> */}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;
