import React from "react";
import "./../../Pages/IndustriesWorkWith/Industries.css";

const IndustriesSection = () => {
  return (
    <div className="container-fluid industries">
      <div className="container">
        <div className="main-in">
          <div className="toppart text-center">
            <h2 className="game-feature-heading1">Industries We Work With</h2>
            <p className="game-feature-para1">Latest and Greatest Services</p>
          </div>
          <div className="box">
            <div className="row">
              <div className="col-12">
                <ul className="row d-flex justify-content-center p-0">
                  <li className=" border-bottom  border-right full-width-image-industries mobile-border-left mobile-border-right mobile-border-bottom">
                    <a className="industrylinks">
                      <div
                        className="d-flex col-md-12 mb-2  justify-content-center"
                        style={{ height: "2rem" }}
                      >
                        <img
                          className=""
                          src={require("./../../Assets/Images/e-commerce.png")}
                        ></img>
                      </div>
                      {/* <img src="https://www.techgropse.com/common/images/updated/ecommerce.png" alt="Ecommerce" /> */}
                      <h5>E-Commerce</h5>
                    </a>
                  </li>
                  <li className=" border-bottom border-right full-width-image-industries mobile-border-left mobile-border-right mobile-border-bottom">
                    <a className="industrylinks">
                      <div
                        className="d-flex col-md-12 mb-2  justify-content-center"
                        style={{ height: "2rem" }}
                      >
                        <img
                          className=""
                          src={require("./../../Assets/Images/education-system-blue.png")}
                        ></img>
                      </div>
                      {/* <img src="https://www.techgropse.com/common/images/updated/education.png" alt="Education" /> */}
                      <h5>Education</h5>
                    </a>
                  </li>

                  {/* <li className="border-right full-width-image-industries">
                    <a className="industrylinks"  >
                      <img src="https://www.techgropse.com/common/images/updated/game.png" alt="Game" />
                      <h5>Game</h5>
                    </a>
                  </li> */}

                  <li className=" border-bottom full-width-image-industries mobile-border-left mobile-border-right mobile-border-bottom">
                    <a className="industrylinks">
                      <div
                        className="d-flex col-md-12 mb-2 justify-content-center"
                        style={{ height: "2rem" }}
                      >
                        <img
                          className=""
                          //  src="https://www.techgropse.com/common/images/updated/real.png" alt="Real Estate" />
                          src={require("./../../Assets/Images/hotel-management.png")}
                        ></img>
                      </div>

                      <h5>Hotel Management</h5>
                    </a>
                  </li>
                </ul>
                <ul className="row d-flex justify-content-center p-0">
                  <li className=" border-right full-width-image-industries mobile-border-left mobile-border-right mobile-border-bottom">
                    <a className="industrylinks">
                      {/* <img src="https://www.techgropse.com/common/images/updated/healthcare.png" alt="Healthcare" /> */}
                      <div
                        className="d-flex col-md-12 mb-2  justify-content-center"
                        style={{ height: "2rem" }}
                      >
                        <img
                          className=""
                          src={require("./../../Assets/Images/food-delivery.png")}
                        ></img>
                      </div>
                      <h5>Food Delivery</h5>
                    </a>
                  </li>
                  <li className=" border-right full-width-image-industries mobile-border-left mobile-border-right mobile-border-bottom">
                    <a className="industrylinks">
                      <div
                        className="d-flex col-md-12 mb-2  justify-content-center"
                        style={{ height: "2rem" }}
                      >
                        <img
                          className=""
                          // src="https://www.techgropse.com/common/images/updated/ondemand.png" alt="On-Demand" />
                          src={require("./../../Assets/Images/social-media.png")}
                        ></img>
                      </div>
                      <h5>Social Media</h5>
                    </a>
                  </li>
                  <li className="full-width-image-industries mobile-border-left mobile-border-right mobile-border-bottom">
                    <a className="industrylinks">
                      {/* <img src="https://www.techgropse.com/common/images/updated/grocery.png" alt="Grocery" /> */}
                      <div
                        className="d-flex col-md-12  mb-2 justify-content-center"
                        style={{ height: "2rem" }}
                      >
                        <img
                          className=""
                          src={require("./../../Assets/Images/pos-system.png")}
                        ></img>
                      </div>
                      <h5>POS System</h5>
                    </a>
                  </li>
                  {/* <li className="full-width-image-industries">
                    <a className="industrylinks"  >
                      <img src="https://www.techgropse.com/common/images/updated/fitness.png" alt="Fitness Center" />
                      <h5>Fitness Center</h5>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustriesSection;
